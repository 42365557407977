import React from 'react';
import '../assets/css/main.scss';
import Layout from '../components/layout/layout';
import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import Hero from '../components/hero/hero';
import './../assets/icons/font-awesome';
import {TechnologyStack} from '../components/technology-stack/technology-stack';
import CallToAction from '../components/cta/cta';

const Technology = ({ children }: any) => {
  const { language } = useI18next();
  return (
    <Layout pageTitle={ language === 'nl' ? 'Met welke technologieën heb ik ervaring' : 'What technologies do I have experience with' }>
      <>
        {children}

        <Hero size={'small'}/>

        {
          language === 'nl'&&
          <>
            <header>
              <h1>Technologie <span>Met welke technologieën heb ik ervaring</span></h1>
            </header>

            <p>
              Het vaststellen van eisen en het verfijnen en uitwerken daarvan is een voortdurend proces welke essentieel is binnen mijn werkprocess.
              Door het zoeken naar balans tussen kwaliteit en continuiteit ben ik altijd bezig met het verbeteren van de software-architectuur.
            </p>

          </>
        }
        {
          language === 'en'&&
          <>
            <header>
              <h1>Technology <span>What technologies do I have experience with</span></h1>
            </header>

            <p>
              Determining requirements and refining and elaborating them is an ongoing process that is essential within my work process.
              By looking for a balance between quality and continuity, I am always working on improving the software architecture.
            </p>
          </>
        }

        <TechnologyStack/>

        <CallToAction>
          <>
            {
              language === 'nl'&&
              <>
                <h2>Heeft u software op maat nodig?</h2>
                <h3>Bent u benieuwd hoe ik u hierbij kan helpen?</h3>
              </>
            }
            {
              language === 'en'&&
                <>
                  <h2>Do you need custom software?</h2>
                  <h3>Are you curious how I can help you with this?</h3>
                </>
            }
          </>
        </CallToAction>
      </>
    </Layout>
  );
};
export default Technology;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
