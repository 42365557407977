import React from 'react';
import '../../assets/icons/font-awesome';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useI18next} from 'gatsby-plugin-react-i18next';

type CallToActionArgs = {
  children: React.ReactChild
}
export default function CallToAction({children}: CallToActionArgs) {
  const { language } = useI18next();
  return (
    <p className={'cta'}>
      {children}
      <a
        className={'button'}
        href="&#77;&#65;&#73;&#108;&#116;&#111;&#58;&#108;&#101;&#101;&#110;&#100;&#101;&#114;&#116;&#64;&#107;&#111;&#107;&#116;&#97;&#105;&#108;&#46;&#110;&#108;"
      >
        <FontAwesomeIcon icon={'envelope'}/>
        <span className={'label'}>
          {
            language === 'nl' && 'Neem contact op via e-mail'
          }
          {
            language === 'en' && 'Contact me by email'
          }
        </span>
      </a>
    </p>
  );
}

