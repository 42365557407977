import * as React from 'react';
import './technology-stack.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// @ts-ignore
import GatsbyIcon from './../../assets/icons/gatsby.svg';
// @ts-ignore
import HomeyIcon from './../../assets/icons/homey.svg';
// @ts-ignore
import NxIcon from './../../assets/icons/nx.svg';
// @ts-ignore
import TailwindIcon from './../../assets/icons/tailwind.svg';
import {useI18next} from 'gatsby-plugin-react-i18next';

export const TechnologyStack = () => {
  const { language } = useI18next();
  return <section className="technology">
    {
      language === 'nl' &&
      <h3>
        De volgende technologieën helpen mij bij het ontwikkelen van software-oplossingen:
      </h3>
    }
    {
      language === 'en' &&
      <h3>
        The following technologies help me develop software solutions:
      </h3>
    }

    <ul className={'tag'}>
      <li>
        <a href="https://homey.app/" className="icon solid homey" target={'_blank'}>
          <HomeyIcon/>
          <span className="label">Homey</span>
        </a>
      </li>
      <li>
        <a href="https://angular.io/" className="icon brands fa-angular" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'angular']}/>
          <span className="label">Angular</span>
        </a>
      </li>
      <li>
        <a href="https://reactjs.org/" className="icon brands fa-react" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'react']}/>
          <span className="label">React</span>
        </a>
      </li>
      <li>
        <a href="https://nodejs.org/en/" className="icon brands fa-node" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'node']}/>
          <span className="label">Node</span>
        </a>
      </li>
      <li>
        <a href="https://nodejs.org/en/" className="icon brands fa-node-js" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'node-js']}/>
          <span className="label">NodeJS</span>
        </a>
      </li>
      <li>
        <a href="https://reactnative.dev/" className="icon brands fa-react" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'react']}/>
          <span className="label">React native</span>
        </a>
      </li>
      <li>
        <a href="https://developer.android.com/" className="icon brands fa-android" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'android']}/>
          <span className="label">Android</span>
        </a>
      </li>
      <li>
        <a href="https://developer.apple.com/" className="icon brands fa-apple" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'apple']}/>
          <span className="label">iOS</span>
        </a>
      </li>
      <li>
        <a href="https://www.php.net/" className="icon brands fa-php" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'php']}/>
          <span className="label">Php</span>
        </a>
      </li>
      <li>
        <a href="https://symfony.com/" className="icon brands fa-symfony" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'symfony']}/>
          <span className="label">Symfony</span>
        </a>
      </li>
      <li>
        <a href="https://www.gatsbyjs.com/" className="icon solid gatsby" target={'_blank'}>
          <GatsbyIcon/>
          <span className="label">gatsby</span>
        </a>
      </li>
      <li>
        <a href="https://nx.dev/" className="icon solid nx" target={'_blank'}>
          <NxIcon/>
          <span className="label">Nx</span>
        </a>
      </li>
      <li>
        <a href="https://tailwindcss.com/" className="icon solid tailwind" target={'_blank'}>
          <TailwindIcon/>
          <span className="label">Tailwind</span>
        </a>
      </li>
      <li>
        <a href="https://www.figma.com/" className="icon brands fa-figma" target={'_blank'}>
          <FontAwesomeIcon icon={['fab', 'figma']}/>
          <span className="label">Figma</span>
        </a>
      </li>
    </ul>
  </section>;
};
